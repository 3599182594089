/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
export const MdxSection = ({children, title}) => {
  const _components = Object.assign({
    section: "section",
    h3: "h3"
  }, _provideComponents());
  return React.createElement(_components.section, {
    id: title
  }, React.createElement(_components.h3, null, title), children);
};
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(MdxSection, {
    title: "Problem"
  }, React.createElement(_components.p, null, "Recently while updating with with webpack, I encountered a weird error where I wasn't able to run a simple script:"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "#!/bin/bashc/\ndocker-compose exec -w /var/www/html/wp-content/themes/skela wordpress composer \"$@\"\n")), React.createElement(_components.p, null, "When trying to run this script via ", React.createElement(_components.code, null, "./bin/composer install"), ", I got this error in my terminal:"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ERROR: Setting workdir for exec is not supported in API < 1.35 (1.30)\n")), React.createElement(_components.p, null, "The error was coming from the ", React.createElement(_components.code, null, "-w"), " flag in the ", React.createElement(_components.code, null, "docker-compose exec"), " command in the ", React.createElement(_components.code, null, "composer"), " script.")), "\n", React.createElement(MdxSection, {
    title: "Solution"
  }, React.createElement(_components.p, null, "Turns The fix was to update the version in my ", React.createElement(_components.code, null, "docker-compose.yml"), " file to from version ", React.createElement(_components.code, null, "3.5"), " to ", React.createElement(_components.code, null, "3.6"), ". It's strange because 3.5 isn't anywhere close to the API version ", React.createElement(_components.code, null, "1.35"), " from the error message 🤷‍♀️"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-yaml"
  }, "version: '3.6' // highlight-line\nservices:\n  wordpress:\n    build:\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
